figure.image {
  margin: 0.9rem auto;
  text-align: center;
}

figure.image-style-side {
  float: right;
}

figure.image_resized img {
  width: 100%;
}
figure.media{
  display: block;
}